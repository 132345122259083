import { ChangeDetectionStrategy, Component, forwardRef, Input, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { LetDirective } from '@shared/ui';

import { IconComponent } from '../../icon';
import { AbstractInputComponent } from '../abstract-input.component';
import { ValidationMessageComponent } from '../validation-message';
import { ControlType } from '../models';

@Component({
  standalone: true,
  selector: 'cab-simple-input',
  templateUrl: './simple-input.component.html',
  styleUrl: './simple-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    NgClass,
    AsyncPipe,
    ReactiveFormsModule,
    IconComponent,
    // DropdownComponent,
    LetDirective,
    TranslateModule,
    ValidationMessageComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleInputComponent),
      multi: true,
    },
  ],
})
export class SimpleInputComponent extends AbstractInputComponent<string | number> {
  /**
   * Icon of input
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() iconTemplate: TemplateRef<any>;
  /**
   * Control type
   */
  @Input() controlType: ControlType = 'text';
  /**
   * Input autocomplete
   */
  @Input() autocomplete: string;
  /**
   * Label should be pinned as active state
   */
  @Input() isLabelPinned: boolean;
}
